import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export const FadeInZoom = memo(function FadeInZoom({ children }: any) {
  const [ref, isVisible] = useInView({ threshold: 0.5, triggerOnce: true })
  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        bounce: 0,
        duration: 1.5,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
    hidden: {
      scale: 1.2,
      opacity: 0,
      y: 0,
    },
  }

  return (
    <motion.div
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      variants={variants}
    >
      {children}
    </motion.div>
  )
})
