import styled from '@emotion/styled'
import {
  Accordion,
  Props as AccordionProps,
} from 'app/components/Common/Accordion'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  title?: string
  items: AccordionProps[]
}

export const BlockAccordion = memo(function BlockAccordion({
  title,
  items,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
        </FadeInUp>
      ) : null}
      {items && items.length > 1 ? <Accordion items={items as any} /> : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 8vw 0;
  padding: 0 10vw;

  @media (max-width: 991px) {
    margin: 60px 0;
    padding: 0 30px;
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 2.5vw;
  margin: 3vw 0 2vw 0;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 991px) {
    font-size: 24px;
    line-height: 1;
    margin: 20px 0;
  }
`
