import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer } from 'react-leaflet'

export interface Props {
  address?: string
  email?: string
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  phone?: string
  siteName?: string
  title?: string
  titleDownload?: string
  buttonsList?: ButtonProps[]
}

export const BlockMap = memo(function BlockMap({
  address,
  email,
  googleMapsURL,
  languageCode,
  longitude,
  latitude,
  phone,
  siteName,
  title,
  titleDownload,
  buttonsList,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const bounds = [] as any

  bounds.push([latitude, longitude])

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [96, 154],
        iconAnchor: [48, 154],
        html: `<img src="/map_marker.svg" alt="Hotel Corallo" width="96" height="154">`,
        className: 'main-icon',
      }),
    )
  }, [])
  return (
    <Container>
      <Wrapper>
        <MapWrapper>
          <img src="/map.jpg" />
        </MapWrapper>
        <TextWrapper>
          {title ? (
            <FadeInUp>
              <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
            </FadeInUp>
          ) : null}
          {address ? (
            <FadeInUp>
              <Text dangerouslySetInnerHTML={{ __html: address }}></Text>
            </FadeInUp>
          ) : null}
          {googleMapsURL ? (
            <FadeInUp>
              <Button target="_blank" URL={googleMapsURL} label="Google Maps" />
            </FadeInUp>
          ) : null}

          <ButtonsList>
            {titleDownload ? (
              <FadeInUp>
                <Subtitle
                  dangerouslySetInnerHTML={{ __html: titleDownload }}
                ></Subtitle>
              </FadeInUp>
            ) : null}

            {buttonsList
              ? buttonsList.map((button, index) => (
                  <FadeInUp>
                    <React.Fragment key={index}>
                      <a
                        href={button.file ? button.file : button.URL}
                        target={button.target}
                      >
                        {button.label}
                      </a>
                    </React.Fragment>
                  </FadeInUp>
                ))
              : undefined}
          </ButtonsList>
        </TextWrapper>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding-right: 8vw;

  @media (max-width: 991px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  position: relative;

  @media (max-width: 991px) {
    display: block;
  }
`

const MapWrapper = styled.div`
  width: 68%;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

const TextWrapper = styled.div`
  width: 32%;
  padding: 0 0 0 6vw;
  position: relative;

  @media (max-width: 991px) {
    width: 100%;
    padding: 30px;
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 2.5vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Subtitle = styled.h3`
  font-weight: 200;
  font-size: 2vw;
  margin: 2vw 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const Text = styled.p`
  margin: 2vw 0;

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0;
  }
`

const ButtonsList = styled.div`
  margin-top: 4vw;
  border-top: 1px solid ${({ theme }) => theme.colors.variants.neutralLight4};

  a {
    display: block;
    font-size: 0.9vw;
    letter-spacing: 2px;
    margin-bottom: 0.5vw;
    letter-spacing: 0.1vw;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    font-family: ${({ theme }) => theme.fontFamily.subheading};
    position: relative;
    padding-left: 2vw;

    background: left center url('/ico_download.svg') no-repeat;
    background-size: auto 90%;
  }

  @media (max-width: 1439px) {
    a {
      font-size: 14px;
    }
  }

  @media (max-width: 991px) {
    margin-top: 40px;

    a {
      padding-left: 30px;
      margin-bottom: 10px;
    }
  }
`

const Map = styled(MapContainer)`
  width: 100%;
  height: 40vw;

  @media (max-width: 991px) {
    height: 50vh;
  }

  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 1 !important;

    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;

      a {
        position: relative;
        width: 3.4375rem;
        height: 3.4375rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight1};
        border: 0;
        border-radius: 0% !important;
        line-height: 2.875rem;
        text-indent: -9999px;
        transition: 0.2s ease-out;

        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.neutralDark1};

            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.neutralLight1};
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            opacity: 0.2;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }
`
